import Typography from "typography"
import kirkhamTheme from "typography-theme-kirkham"

kirkhamTheme.baseFontSize = "18px"

kirkhamTheme.overrideStyles = ({ adjustFontSizeTo, scale, rhythm }, options) => ({
  a: {
    color: "#9f392b",
    fontFamily: ['Playfair Display', 'serif'].join(','),
  }
})
const typography = new Typography(kirkhamTheme)
export default typography

export const rhythm = typography.rhythm
