import React from "react";
// import { css } from "@emotion/core"

export default function Heading(props) {
  return (
    <h1 className={props.classNames}>
      { props.headerText }
    </h1>
  )
}
